import * as Yup from 'yup'
import formModel from './model'

const {
  formField: { username, password },
} = formModel

const getSchema = (): unknown[] => {
  return [
    Yup.object().shape({
      [username.value]: Yup.string().required(`${username.requiredErrorMsg}`),
      [password.value]: Yup.string()
        .required(`${password.requiredErrorMsg}`)
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
          `PasswordValidationError`
        ),
    }),
  ]
}

export default getSchema
