import * as types from './actionsTypes'
import initialState from '../../core/state/reducers/initialState'
import { StatsData } from '../utils/statsModel'

const loginReducer = (
  state = initialState.stats as StatsData,
  action: { type: string; data: StatsData }
): StatsData => {
  switch (action.type) {
    case types.GET_TOP10_SUCCESS:
      return action.data as StatsData
    default:
      return state
  }
}

export default loginReducer
