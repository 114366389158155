import { combineReducers } from 'redux'
import profile from '../../../profile/state/profileReducer'
import appConfig from '../../appConfig/state/configReducer'
import stats from '../../../homePage/state/homePageReducer'
import games from '../../../games/state/gamesReducer'
import players from '../../../players/state/playersReducer'

const rootReducer = combineReducers({
  appConfig,
  profile,
  stats,
  games,
  players,
})

export default rootReducer
