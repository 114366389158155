/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import * as ROUTES from '../../core/routes/constants'

interface AuthRouteType {
  exact?: boolean
  path: string
  component: React.FC<any>
}

const AuthRoute: React.FC<AuthRouteType> = ({
  exact,
  path,
  component: Component,
  ...rest
}) => {
  const auth = localStorage.getItem('token')

  const checkIfLoginRoute = (pathname: string) => {
    if (pathname !== ROUTES.LOGIN && pathname !== ROUTES.REGISTER)
      return <Component {...rest} />
    return <Redirect to={{ pathname: ROUTES.ROOT }} />
  }

  const render = (pathname: string) => {
    if (auth) {
      return checkIfLoginRoute(pathname)
    } else {
      if (pathname === ROUTES.PROFILE)
        return <Redirect to={{ pathname: ROUTES.LOGIN }} />
      else return <Component {...rest} />
    }
  }
  return (
    <Route exact={exact} path={path}>
      {render(path)}
    </Route>
  )
}

export default AuthRoute
