import {
  handleError,
  handleResponse,
  getBaseUrl,
} from '../../core/api/apiUtils'
import { Player } from './playersModel'

export const get = async (id: number): Promise<Player[]> => {
  const url = getBaseUrl(`/api/player/${id}`)
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return (await handleResponse(response)) as Promise<Player[]>
  } catch (err) {
    throw handleError(err)
  }
}
