import { Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import React from 'react'
import AvatarNavElement from '../../components/CustomNavElements/AvatarNavElement'
import { Player } from '../../players/utils/playersModel'

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    transition: '0.5s',
    '&:hover': {
      transform: 'scale(1.05)',
    },
    [theme.breakpoints.down('md')]: {
      width: theme.spacing(9),
      height: theme.spacing(9),
    },
    [theme.breakpoints.down('xs')]: {
      width: theme.spacing(6),
      height: theme.spacing(6),
    },
  },
}))

interface Props {
  data: Player
  isMdUp: boolean
}

const RankingCard: React.FC<Props> = ({ data, isMdUp }) => {
  const classes = useStyles()
  const {
    player_id,
    username,
    img,
    points_total,
    throws_total,
    ranking_points_total,
    games_played,
    games_won,
    player_rank,
  } = data

  const gridXs = isMdUp ? 2 : 6
  return (
    <>
      <Grid item xs={1}>
        <Typography variant="h6" style={{ fontWeight: 700 }} align="center">
          {player_rank}.
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <AvatarNavElement
          to={player_id}
          img={img}
          alt={username}
          className={classes.avatar}
        />
      </Grid>
      <Grid item xs={gridXs}>
        <Typography variant="h6" style={{ fontWeight: 700 }} align="center">
          {username}
        </Typography>
      </Grid>
      {isMdUp && (
        <>
          <Grid item xs={2}>
            <Typography variant="h6" style={{ fontWeight: 700 }} align="center">
              {(points_total / throws_total).toFixed(1)}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h6" style={{ fontWeight: 700 }} align="center">
              {Math.floor((games_won / games_played) * 100)}%
            </Typography>
          </Grid>
        </>
      )}
      <Grid item xs={2}>
        <Typography variant="h6" style={{ fontWeight: 700 }} align="center">
          {ranking_points_total.toFixed(1)}
        </Typography>
      </Grid>
    </>
  )
}

export default RankingCard
