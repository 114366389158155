import * as Api from '../utils/gamesApi'
import * as types from './actionsTypes'
import { GetGamesAction } from '../utils/gamesModel'

export const getGames = (id: number, limit: number): GetGamesAction => {
  return async (dispatch) => {
    try {
      const parsedData = await Api.getGames(id, limit)
      dispatch({ type: types.GET_GAMES_SUCCESS, data: parsedData })
    } catch (err) {
      throw err
    }
  }
}
