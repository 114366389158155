export type setFieldErrorType = (
  field: string,
  message: string | undefined
) => void

export interface ValidationError {
  [key: string]: { value: string; message: string } | undefined
  property?: { value: string; message: string }
}

export interface ValuesType {
  [key: string]: string
  username: string
  password: string
  first_name: string
  last_name: string
  phone_number: string
  birthday: string
  email: string
  confirm_password: string
  img: string
}

export const handleValidationError = (
  data: { property: string; value: string[] } | number,
  setFieldError: setFieldErrorType,
  values: ValuesType
): ValidationError => {
  const handler: ValidationError = {}
  for (const [property, value] of Object.entries(data)) {
    setFieldError(property, value)
    handler[property] = { value: values[property], message: value }
  }
  return handler
}
