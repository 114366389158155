import * as types from './actionTypes'
import initialState from '../../state/reducers/initialState'

type StateType = {
  choosenTheme: string
  choosenLang: string
}

const appConfigReducer = (
  state = initialState.appConfig,
  action: { type: string; payload: string }
): StateType => {
  switch (action.type) {
    case types.HANDLE_LANG_SELECTION:
      return {
        ...state,
        choosenLang: action.payload,
      }
    case types.HANDLE_THEME_SELECTION:
      return {
        ...state,
        choosenTheme: action.payload,
      }
    default:
      return state
  }
}

export default appConfigReducer
