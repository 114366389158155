import React, { ReactChild, ReactElement, useState } from 'react'
import { Grid, IconButton, makeStyles, } from '@material-ui/core'
import InputField from '../../../components/formFields/InputTextField'
import DatePickerField from '../../../components/formFields/DatePickerField'
import ImputFile from '../../../components/formFields/InputFileField'
import CancelIcon from '@material-ui/icons/Cancel'
import Tooltip from '@material-ui/core/Tooltip'
import { useTranslation } from 'react-i18next'
import Avatar_IMG from '../../../../asset/resource/avatar.png'
import { RegistrationFieldsProps } from '../../utils/registrationModel'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import VisibilityIcon from '@material-ui/icons/Visibility'

const useStyles = makeStyles((theme) => ({
  removeImg: {
    position: 'absolute',
    top: '0px',
    right: '20%',
    [theme.breakpoints.down('xs')]: {
      right: '6%',
    },
  },
  uploadFileWrap: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: '136px',
    alignItems: 'center',
    marginTop: '20px',
    marginBottom: '20px',
    position: 'relative',
  },
  imgWrap: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: '100px',
    maxHeight: '100px',
    width: '100px',
    alignItems: 'center',
    overflow: 'hidden',
    borderRadius: '50%',
  },
  img: { width: '100px', borderRadius: '15px' },
}))

const UserInformationForm: React.FC<RegistrationFieldsProps> = ({
  formField,
  setSelected,
  selected,
  setRegisterError,
}): ReactElement => {
  const {
    username,
    first_name,
    last_name,
    email,
    phone_number,
    birthday,
    password,
    confirm_password,
  } = formField

  const classes = useStyles()
  const { t } = useTranslation()
  const [passwordType, setPasswordType] = useState('password')

  const handlePasswordType = () => {
    if (passwordType === 'password') {
      setPasswordType('text')
    } else {
      setPasswordType('password')
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <InputField
          name={username.value}
          label={username.label}
          fullWidth
          type="text"
          autoComplete="off"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField
          name={first_name.value}
          label={first_name.label}
          fullWidth
          type="text"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField
          name={last_name.value}
          label={last_name.label}
          fullWidth
          type="text"
        />
      </Grid>

      <Grid item xs={12}>
        <InputField
          name={email.value}
          label={email.label}
          fullWidth
          type="text"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField
          name={phone_number.value}
          label={phone_number.label}
          fullWidth
          type="text"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DatePickerField
          name={birthday.value}
          label={birthday.label}
          format="dd.MM.yyyy"
          minDate={new Date('1920/12/31')}
          maxDate={new Date()}
        />
      </Grid>
      <Grid item xs={12} sm={6} style={{ position: 'relative' }}>
        <InputField
          name={password.value}
          label={password.label}
          fullWidth
          type={passwordType}
        />
        <IconButton
          aria-label="toggle password visibility"
          onClick={handlePasswordType}
          style={{ position: 'absolute', right: '0px', top: '20px' }}
        >
          {passwordType === 'text' ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </IconButton>
      </Grid>

      <Grid style={{ display: 'flex' }} item xs={12} sm={6}>
        <InputField
          name={confirm_password.value}
          label={confirm_password.label}
          fullWidth
          type={passwordType}
        />
      </Grid>
      <Grid item xs={6} className={classes.uploadFileWrap}>
      
        <ImputFile
          name={'img'}
          setSelected={setSelected}
          setOverSizeError={setRegisterError}
        />
      </Grid>

      <Grid item xs={6} className={classes.uploadFileWrap}>
        {selected && (
          <Tooltip
            className={classes.removeImg}
            title={t('Remove') as ReactChild}
          >
            <IconButton onClick={() => setSelected('')}>
              <CancelIcon color="error" />
            </IconButton>
          </Tooltip>
        )}
        <div className={classes.imgWrap}>
          <img
            className={classes.img}
            src={selected ? URL.createObjectURL(selected) : Avatar_IMG}
          ></img>
        </div>
      </Grid>
    </Grid>
  )
}

export default UserInformationForm
