import { Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import React from 'react'
import AvatarNavElement from '../../components/CustomNavElements/AvatarNavElement'
import { GamePlayers } from '../utils/gamesModel'

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    transition: '0.5s',
    '&:hover': {
      transform: 'scale(1.05)',
    },
    [theme.breakpoints.down('xs')]: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
  },
}))

interface Props {
  data: GamePlayers
  isMdUp: boolean
}

const RankingCard: React.FC<Props> = ({ data, isMdUp }) => {
  const classes = useStyles()
  const {
    player_id,
    username,
    img,
    game_rank,
    points,
    throws,
    ranking_points,
  } = data

  const gridXs = isMdUp ? 3 : 5
  return (
    <>
      <Grid item xs={2} md={1}>
        <Typography variant="h6" style={{ fontWeight: 700 }} align="center">
          {game_rank}.
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <AvatarNavElement
          to={player_id}
          img={img}
          alt={username}
          className={classes.avatar}
        />
      </Grid>
      <Grid item xs={gridXs}>
        <Typography variant="h6" style={{ fontWeight: 700 }} align="center">
          {username}
        </Typography>
      </Grid>
      {isMdUp && (
        <>
          <Grid item xs={1}>
            <Typography variant="h6" style={{ fontWeight: 700 }} align="center">
              {points}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="h6" style={{ fontWeight: 700 }} align="center">
              {throws}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="h6" style={{ fontWeight: 700 }} align="center">
              {(points / throws).toFixed(1)}
            </Typography>
          </Grid>
        </>
      )}
      <Grid item xs={2}>
        <Typography variant="h6" style={{ fontWeight: 700 }} align="center">
          {ranking_points.toFixed(1)}
        </Typography>
      </Grid>
    </>
  )
}

export default RankingCard
