import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { CircularProgress } from '@material-ui/core'
import SignupFields from './utils/fields'
import getSchema from './utils/validationSchema'
import LogInFormModel from './utils/model'
import { Formik, Form } from 'formik'
import { useHistory } from 'react-router-dom'
import * as ROUTES from '../../core/routes/constants'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { LoginData } from '../loginModel'
const { formId, formField } = LogInFormModel

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '30px 30px 10px',
    borderRadius: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    minWidth: '90px',
  },
  paperHeader: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(3, 2, 2),
    textTransform: 'none',
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(0.7),
    paddingBottom: theme.spacing(1),
  },
  formTitle: { fontWeight: 700, marginBottom: '20px' },
}))

type LogInFormProps = {
  requestError: string
  handleSubmit: (
    data: LoginData,
    setSubmitting: (isSubmitting: boolean) => void
  ) => Promise<void>
  setRequestError: React.Dispatch<React.SetStateAction<string>>
}
const initialValues = {
  username: '',
  password: '',
}

const LogInForm: React.FC<LogInFormProps> = ({
  requestError,
  handleSubmit,
  setRequestError,
}): ReactElement => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const validationSchema = getSchema()[0]
  return (
    <Paper elevation={4} className={classes.paper}>
      <Typography variant="h5" className={classes.formTitle} align="center">
        {`${t('LogIn')}`}
      </Typography>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values, setSubmitting)
        }}
      >
        {({ isSubmitting }) => (
          <Form
            onClick={() => {
              setRequestError('')
            }}
            id={formId}
          >
            <SignupFields formField={formField} />
            <div className={classes.buttons}>
              <Button
                disabled={isSubmitting}
                variant="outlined"
                color="secondary"
                className={classes.button}
                onClick={() => history.push(ROUTES.REGISTER)}
              >
                {t('Registration')}
              </Button>
              <Button
                disabled={isSubmitting}
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {isSubmitting ? <CircularProgress size={22} /> : t('LogIn')}
              </Button>
            </div>
            <Box className={classes.box}>
              {requestError && (
                <>
                  <HighlightOffIcon
                    color="error"
                    style={{ marginRight: '7px' }}
                  />
                  <Typography color="error" variant="body2">
                    {t(requestError)}
                  </Typography>
                </>
              )}
            </Box>
          </Form>
        )}
      </Formik>
    </Paper>
  )
}

export default LogInForm
