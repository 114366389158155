import React, { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { CircularProgress } from '@material-ui/core'
import SignupFields from './utils/fields'
import getSchema from './utils/validationSchema'
import SignupFormModel from './utils/model'
import { Formik, Form } from 'formik'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { RegistrationFormProps } from '../utils/registrationModel'
const { formId, formField } = SignupFormModel

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
    borderRadius: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    minWidth: "120px"
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '0px',
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: "center",
    marginTop: theme.spacing(1.5),
  },
  formTitle: { fontWeight: 700, marginBottom: '20px' },
}))

const initialValues = {
  username: '',
  password: '',
  first_name: '',
  last_name: '',
  phone_number: '',
  birthday: '',
  email: '',
  confirm_password: '',
  img: '',
}

const RegistrationForm: React.FC<RegistrationFormProps> = ({
  registerError,
  handleSubmit,
  setRegisterError,
  validationErrors,
}): ReactElement => {
  const classes = useStyles()
  const { t } = useTranslation()
  const currentValidationSchema = getSchema(validationErrors)[0]
  const [selected, setSelected] = useState<string | File>('')
  return (
    <Paper elevation={4} style={{ padding: '30px' }}>
      <Typography variant="h5" className={classes.formTitle} align="center">
        {`${t('Registration')}`}
      </Typography>

      <Formik
        initialValues={initialValues}
        validationSchema={currentValidationSchema}
        onSubmit={(values, { setSubmitting, setFieldError }) => {
          handleSubmit(values, setSubmitting, setFieldError, selected)
        }}
      >
        {({ isSubmitting }) => (
          <Form
            onClick={() => {
              setRegisterError('')
            }}
            id={formId}
          >
            <SignupFields
              formField={formField}
              setSelected={setSelected}
              selected={selected}
              setRegisterError={setRegisterError}
            />
            <div className={classes.buttons}>
              <Button
                disabled={isSubmitting}
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {isSubmitting ? <CircularProgress size={22} /> : t('Confirm')}
              </Button>
            </div>
            <Box className={classes.box}>
              {registerError && (
                <>
                  <HighlightOffIcon
                    color="error"
                    style={{ marginRight: '7px' }}
                  />
                  <Typography color="error" variant="body2">
                    {t(registerError)}
                  </Typography>
                </>
              )}
            </Box>
          </Form>
        )}
      </Formik>
    </Paper>
  )
}

export default RegistrationForm
