import * as types from './actionsTypes'
import initialState from '../../core/state/reducers/initialState'
import { User } from '../utils/profileModel'

const loginReducer = (
  state = initialState.profile as User,
  action: { type: string; data: User }
): User => {
  switch (action.type) {
    case types.USER_LOGIN_SUCCESS:
      return action.data as User
    case types.USER_LOGOUT:
      return {} as User
    default:
      return state
  }
}

export default loginReducer
