import * as types from './actionsTypes'
import * as Api from '../utils/profileApi'
import { User } from '../utils/profileModel'
import { LoginData } from '../../logIn/loginModel'

export type LogInAction = (
  dispatch: (arg0: { type: string; data: User }) => void
) => Promise<void>

export const logIn = (data: LoginData): LogInAction => {
  return async (dispatch) => {
    try {
      const parsedData = await Api.login(data)
      localStorage.setItem('token', parsedData.token)
      dispatch({ type: types.USER_LOGIN_SUCCESS, data: parsedData })
    } catch (err) {
      throw err
    }
  }
}

export const checkSession = (): LogInAction => {
  return async (dispatch) => {
    try {
      const parsedData = await Api.checkSession()
      localStorage.setItem('token', parsedData.token)
      dispatch({ type: types.USER_LOGIN_SUCCESS, data: parsedData })
    } catch (err) {
      throw err
    }
  }
}

export type LogOut = (dispatch: (arg0: { type: string }) => void) => void

export const logOut = (): LogOut => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.USER_LOGOUT })
    } catch (err) {
      throw err
    }
  }
}
