import * as types from './actionsTypes'
import initialState from '../../core/state/reducers/initialState'
import { Player } from '../utils/playersModel'
import { USER_LOGOUT } from '../../profile/state/actionsTypes'

const playersReducer = (
  state = initialState.player as Player,
  action: { type: string; data: Player}
): Player => {
  switch (action.type) {
    case types.GET_PLAYER_SUCCESS:
      return action.data as Player
    case USER_LOGOUT:
      return {} as Player
    default:
      return state
  }
}

export default playersReducer