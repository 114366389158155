import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getData } from './state/homePageActions'
import {
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
  Grid,
  Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import TopStatsCard from './components/TopStatsCard'
import Loading from '../components/Loading'
import Ranking from './components/Ranking'
import { Player } from '../players/utils/playersModel'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '80%',
    maxWidth: '1200px',
    marginTop: '10px',
    minHeight: '400px',
    [theme.breakpoints.down('xs')]: {
      width: '94%',
      marginTop: '0px',
      marginBottom: theme.spacing(2),
    },
  },
  imgWrap: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: '180px',
    maxHeight: '180px',
    width: '180px',
    alignItems: 'center',
    overflow: 'hidden',
    borderRadius: '50%',
  },
  qrButtonWrap: {
    display: 'flex',
    justifyContent: 'flex-end !important',
  },
}))

interface Props {
  top10: Player[]
  lastWinner: Player
  averageBest: Player
  bestPWRatio: Player
  getData: () => Promise<void>
}

const HomePage: React.FC<Props> = ({
  top10,
  lastWinner,
  averageBest,
  bestPWRatio,
  getData,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [loadError, setLoadError] = useState('')
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

  const handlePageLoad = async () => {
    try {
      setLoadError('')
      setIsLoading(true)
      await getData()
      setIsLoading(false)
    } catch (err) {
      setLoadError(err)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!top10.length) handlePageLoad()
  }, [])
  return (
    <div className={classes.container}>
      {isLoading || loadError ? (
        <Loading loadError={loadError} paper handleClick={handlePageLoad} />
      ) : (
        <Ranking data={top10} isMdUp={isMdUp} />
      )}
      <Typography
        variant="h3"
        style={{ fontWeight: 700, margin: '70px' }}
        align="center"
      >
        {t('TopStats')}
      </Typography>
      <Grid container spacing={3}>
        <TopStatsCard
          data={averageBest}
          type=""
          isLoading={isLoading}
          loadError={loadError}
        />
        <TopStatsCard
          data={bestPWRatio}
          type="pwratio"
          isLoading={isLoading}
          loadError={loadError}
        />
        <TopStatsCard
          data={lastWinner}
          type="lastWinner"
          isLoading={isLoading}
          loadError={loadError}
        />
      </Grid>
    </div>
  )
}

const mapStateToProps = (state: {
  stats: {
    top10: Player[]
    topStats: {
      lastWinner: Player
      averageBest: Player
      bestPWRatio: Player
    }
  }
}) => {
  return {
    top10: state.stats.top10,
    lastWinner: state.stats.topStats.lastWinner,
    averageBest: state.stats.topStats.averageBest,
    bestPWRatio: state.stats.topStats.bestPWRatio,
  }
}

const mapDispatchToProps = {
  getData,
}
export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
