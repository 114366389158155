import {
  Avatar,
  Theme,
  makeStyles,
  Button,
  CircularProgress,
  Typography,
  Box,
} from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'

const useStyles = makeStyles((theme: Theme) => ({
  wrap: {
    minWidth: '300px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '15px',
  },
  avatar: {
    margin: theme.spacing(3, 0, 4),
    width: theme.spacing(24),
    height: theme.spacing(24),
    [theme.breakpoints.down('md')]: {
      width: theme.spacing(18),
      height: theme.spacing(18),
    },
    [theme.breakpoints.down('xs')]: {
      width: theme.spacing(14),
      height: theme.spacing(14),
    },
  },
  button: {
    margin: theme.spacing(3, 1, 2),
    textTransform: 'none',
  },
  submit: {
    margin: theme.spacing(3, 1, 2),
    minWidth: '90px',
  },
  buttonsWrap: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(1.5),
  },
}))

interface Props {
  selected: File | string
  isLoading: boolean
  requestError: string
  handleConfirm: () => Promise<void>
  handleCancelation: () => void
}

const ChangeImageOverview: React.FC<Props> = ({
  selected,
  isLoading,
  requestError,
  handleConfirm,
  handleCancelation,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <div className={classes.wrap}>
      <Avatar
        alt={'as'}
        src={URL.createObjectURL(selected)}
        className={classes.avatar}
      />
      <div className={classes.buttonsWrap}>
        <Button
          variant="outlined"
          color="secondary"
          className={classes.button}
          onClick={handleCancelation}
        >
          {t('Cancel')}
        </Button>
        <Button
          disabled={isLoading}
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleConfirm}
        >
          {isLoading ? <CircularProgress size={22} /> : t('Confirm')}
        </Button>
        <Box className={classes.box}>
          {requestError && (
            <>
              <HighlightOffIcon color="error" style={{ marginRight: '7px' }} />
              <Typography color="error" variant="body2">
                {t(requestError)}
              </Typography>
            </>
          )}
        </Box>
      </div>
    </div>
  )
}

export default ChangeImageOverview
