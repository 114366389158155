import * as types from './actionTypes'

export const handleLangSelection = (
  payload: string
): { type: string; payload: string } => {
  return { type: types.HANDLE_LANG_SELECTION, payload }
}

export const handleThemeSelection = (
  payload: string
): { type: string; payload: string } => {
  return { type: types.HANDLE_THEME_SELECTION, payload }
}
