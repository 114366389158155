import {
  handleError,
  handleResponse,
  getBaseUrl,
} from '../../core/api/apiUtils'
import { Game } from './gamesModel'

export const getGames = async (id: number, limit: number): Promise<Game[]> => {
  const url = getBaseUrl(`/api/game/${id}/${limit}`)
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return (await handleResponse(response)) as Promise<Game[]>
  } catch (err) {
    throw handleError(err)
  }
}
