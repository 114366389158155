import { makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import ProfileOverview from '../profile/components/ProfileOverview'
import { Player } from './utils/playersModel'
import { getPlayer } from './state/playerActions'
import Loading from '../components/Loading'
import * as ROUTES from '../core/routes/constants'
import { User } from '../profile/utils/profileModel'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '80%',
    maxWidth: '1200px',
    marginTop: '10px',
    [theme.breakpoints.down('xs')]: {
      width: '94%',
      marginTop: '0px',
      marginBottom: theme.spacing(2),
    },
  },
}))
interface Props {
  stats: {
    top10: Player[]
    topStats: {
      lastWinner: Player
      averageBest: Player
      bestPWRatio: Player
    }
  }
  player: Player
  user_id: number
  getPlayer: (id: number) => Promise<void>
}

const PlayerPage: React.FC<Props> = ({ stats, player, user_id, getPlayer }) => {
  const {
    top10,
    topStats: { lastWinner, averageBest, bestPWRatio },
  } = stats
  const classes = useStyles()
  const params = useParams<{ id: string }>()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const history = useHistory()
  const [data, setData] = useState<Player>({} as Player)
  const [isLoading, setIsLoading] = useState(true)
  const [loadError, setLoadError] = useState('')

  const handleGetPlayer = async () => {
    try {
      setLoadError('')
      setIsLoading(true)
      await getPlayer(Number(params.id))
      setIsLoading(false)
    } catch (err) {
      setLoadError(err)
      setIsLoading(false)
    }
  }

  const handlePageLoad = async () => {
    const id = Number(params.id)
    if (id === user_id) return history.replace({ pathname: ROUTES.PROFILE })
    const top10Player = top10.filter((player) => player.player_id === id)
    if (top10Player.length) return handleSetData(top10Player[0] as Player)
    if (id === averageBest.player_id) return handleSetData(averageBest)
    else if (id === lastWinner.player_id) return handleSetData(lastWinner)
    else if (id === bestPWRatio.player_id) return handleSetData(bestPWRatio)
    else if (id === player.player_id) return handleSetData(player)
    else {
      handleGetPlayer()
    }
  }

  const handleSetData = (data: Player) => {
    setData(data)
    setIsLoading(false)
  }

  useEffect(() => {
    handlePageLoad()
  }, [])

  const handlePlayerFetch = () => {
    if (data.player_id) return
    else if (player.player_id) {
      setData(player)
    } else {
      history.push(ROUTES.PAGE_NOT_FOUND)
    }
  }

  useEffect(() => {
    if (!isLoading) {
      handlePlayerFetch()
    }
  }, [isLoading])
  return (
    <div className={classes.container}>
      {isLoading || loadError ? (
        <Loading loadError={loadError} paper handleClick={handleGetPlayer} />
      ) : (
        <ProfileOverview data={data} isMdUp={isMdUp} />
      )}
    </div>
  )
}

const mapStateToProps = (state: {
  stats: {
    top10: Player[]
    topStats: {
      lastWinner: Player
      averageBest: Player
      bestPWRatio: Player
    }
  }
  players: Player
  profile: User
}) => {
  return {
    stats: state.stats,
    player: state.players,
    user_id: state.profile.id,
  }
}

const mapDispatchToProps = {
  getPlayer,
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayerPage)
