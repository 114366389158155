import React from 'react'
import { at } from 'lodash'
import { useField } from 'formik'
import { TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import renderLabelText from './utils/renderLabelText'

const InputField = (props: {
  type: string
  name: string
  variant: 'standard' | 'filled' | 'outlined' | undefined
  label: string
  autoComplete: string
  fullWidth?: boolean
}): JSX.Element => {
  const { t } = useTranslation()
  const { label, autoComplete, type, ...rest } = props
  const [field, meta] = useField(props.name)

  const renderHelperText = () => {
    const [touched, error] = at(meta, 'touched', 'error')
    if (touched && error) {
      return t(error)
    }
  }

  return (
    <TextField
      type={type as string}
      label={renderLabelText(label)}
      error={meta.touched && !!meta.error && true}
      helperText={renderHelperText()}
      inputProps={{
        autoComplete: autoComplete,
      }}
      variant={props.variant}
      {...field}
      {...rest}
    />
  )
}

InputField.defaultProps = {
  type: 'text',
  variant: 'standard',
  autoComplete: 'new-password',
}

export default InputField
