import * as types from './actionsTypes'
import * as Api from '../utils/homePageApi'
import { GetDataAction } from '../utils/statsModel'

export const getData = (): GetDataAction => {
  return async (dispatch) => {
    try {
      const parsedData = await Api.get()
      dispatch({ type: types.GET_TOP10_SUCCESS, data: parsedData })
    } catch (err) {
      throw err
    }
  }
}
