import {
  makeStyles,
  Theme,
} from '@material-ui/core'
import React from 'react'
import GamesOverview from './components/GamesOverview'
import { Game } from './utils/gamesModel'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    maxWidth: '1200px',
    marginTop: '50px',
    padding: "0px 5px",
    [theme.breakpoints.down('xs')]: {
      marginTop: '0px',
      marginBottom: theme.spacing(2),
    },
  },
}))

interface Props {
  data: Game[]
  isMdUp: boolean
  gamesLimitLoading: boolean
  handleGetMoreGames: () => void
}

const Games: React.FC<Props> = ({
  data,
  isMdUp,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      {data.map((game, i) => (
        <GamesOverview key={i} data={game} isMdUp={isMdUp} />
      ))}
    </div>
  )
}

export default Games
