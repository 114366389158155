import { Button, Paper } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    maxWidth: '1200px',
    marginTop: '10px',
    display: 'flex',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginTop: '0px',
      marginBottom: theme.spacing(2),
    },
  },
  buttonsWrap: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

interface Props {
  isDialogOpen: boolean
  setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const ProfileNavigation: React.FC<Props> = ({
  isDialogOpen,
  setIsDialogOpen,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <Paper elevation={1} className={classes.container}>
      <div className={classes.buttonsWrap}>
        <Button
          color="secondary"
          variant="contained"
          style={{ maxHeight: '40px' }}
          onClick={() => setIsDialogOpen(!isDialogOpen)}
        >
          {t('MyQRCode')}
        </Button>
      </div>
    </Paper>
  )
}

export default ProfileNavigation
