import * as types from './actionsTypes'
import * as Api from '../utils/playersApi'
import { GetPlayerAction } from '../utils/playersModel'

export const getPlayer = (id: number): GetPlayerAction => {
  return async (dispatch) => {
    try {
      const parsedData = await Api.get(id)
      dispatch({ type: types.GET_PLAYER_SUCCESS, data: parsedData[0] })
    } catch (err) {
      throw err
    }
  }
}
