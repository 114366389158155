import { Player } from '../../../players/utils/playersModel'

export default {
  appConfig: {
    choosenTheme:
      localStorage.getItem('choosenTheme') != null
        ? (localStorage.getItem('choosenTheme') as string)
        : 'light',
    choosenLang:
      localStorage.getItem('choosenLanguage') != null
        ? (localStorage.getItem('choosenLanguage') as string)
        : 'sr',
  },
  profile: {},
  stats: {
    top10: [] as Player[],
    topStats: {
      lastWinner: {} as Player,
      averageBest: {} as Player,
      bestPWRatio: {} as Player,
    },
  },
  games: [],
  player: {},
}
