import React, { ReactElement, useState } from 'react'
import { Grid, IconButton } from '@material-ui/core'
import InputField from '../../../components/formFields/InputTextField'
import { LogInFieldsProps } from '../../loginModel'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import VisibilityIcon from '@material-ui/icons/Visibility'

const LogInFields: React.FC<LogInFieldsProps> = ({
  formField,
}): ReactElement => {
  const { username, password } = formField
  const [passwordType, setPasswordType] = useState('password')

  const handlePasswordType = () => {
    if (passwordType === 'password') {
      setPasswordType('text')
    } else {
      setPasswordType('password')
    }
  }

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputField
            name={username.value}
            label={username.label}
            fullWidth
            type="text"
            autoComplete="off"
          />
        </Grid>
        <Grid item xs={12} style={{ position: 'relative' }}>
          <InputField
            name={password.value}
            label={password.label}
            fullWidth
            type={passwordType}
          />
          <IconButton
            aria-label="toggle password visibility"
            onClick={handlePasswordType}
            style={{ position: 'absolute', right: '0px', top: '20px' }}
          >
            {passwordType === 'text' ? (
              <VisibilityOffIcon />
            ) : (
              <VisibilityIcon />
            )}
          </IconButton>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default LogInFields
