// import bodyParser from '../../core/utils/bodyParser'
import i18n from 'i18next'

type ResponseType = {
  ok: boolean
  json: () => Promise<Record<string, unknown>>
  status: number
}

export const handleResponse = async (
  response: ResponseType
): Promise<unknown> => {
  if (response.ok) {
    const body = await response.json()
    // return bodyParser(body.data)
    return body
  } else {
    if (!response.status || response.status === 500)
      throw i18n.t('SomethingWentWrong')
    if (response.status === 401) throw 401
    const data = await response.json()
    if (typeof data.message === 'string') throw i18n.t(data.message)
    throw data.message
  }
}
export const handleError = (err: { message: string }): void => {
  if (!err.message) throw err
  err.message = i18n.t('SomethingWentWrong')
  throw err.message
}

export const getBaseUrl = (url: string): string => {
  const api = process.env.API_URL
  return api + url
}
