import React, { useState } from 'react'
import { logIn } from '../profile/state/profileActions'
import LogInForm from './form/LoginForm'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as ROUTES from '../core/routes/constants'
import { LoginData, LogInPageProps } from './loginModel'
import { makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '450px',
    marginTop: '80px',
    [theme.breakpoints.down('xs')]: {
      width: '94%',
      marginTop: '30px',
    },
  },
}))

const LoginPage: React.FC<LogInPageProps> = ({ logIn }) => {
  const classes = useStyles()
  const history = useHistory()
  const [requestError, setRequestError] = useState('')
  const handleSubmit = async (
    data: LoginData,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      await logIn(data)
      history.push(ROUTES.PROFILE)
      setSubmitting(false)
    } catch (err) {
      setRequestError(err)
      setSubmitting(false)
    }
  }
  return (
    <div className={classes.container}>
      <LogInForm
        requestError={requestError}
        handleSubmit={handleSubmit}
        setRequestError={setRequestError}
      />
    </div>
  )
}

const mapDispatchToProps = {
  logIn,
}

export default connect(null, mapDispatchToProps)(LoginPage)
