const model = {
    formId: 'signUpForm',
    formField: {
      username: {
        value: 'username',
        label: 'Username*',
        requiredErrorMsg: 'Required',
      },
      first_name: {
        value: 'first_name',
        label: 'FirstName*',
        requiredErrorMsg: 'Required',
      },
      last_name: {
        value: 'last_name',
        label: 'LastName*',
        requiredErrorMsg: 'Required',
      },
      email: {
        value: 'email',
        label: 'E-mail*',
        requiredErrorMsg: 'Required',
      },
      phone_number: {
        value: 'phone_number',
        label: 'PhoneNumber*',
        requiredErrorMsg: 'Required',
      },
      birthday: {
        value: 'birthday',
        label: 'DateOfBirth*',
        requiredErrorMsg: 'Required',
      },
      password: {
        value: 'password',
        label: 'Password*',
        requiredErrorMsg: 'Required',
      },
      confirm_password: {
        value: 'confirm_password',
        label: 'ConfirmPassword*',
        requiredErrorMsg: 'Required',
      },
    },
  }

  export default model
  