import { Button, Typography } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import React from 'react'
import { useTranslation } from 'react-i18next'
import DoneOutlineRoundedIcon from '@material-ui/icons/DoneOutlineRounded'
import { useHistory } from 'react-router-dom'
import * as ROUTES from '../../core/routes/constants'

type ValidationMessageProps = { validationMessage: string }

const ValidationMessage: React.FC<ValidationMessageProps> = ({
  validationMessage,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  return (
    <Paper
      elevation={4}
      style={{
        padding: '30px',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: "center"
      }}
    >
      <Typography variant="h6" align="center">
        {`${t('ValidationMessageFirst')}`}
      </Typography>
      <Typography variant="h5" color="primary" align="center">
        {`${validationMessage}`}
      </Typography>
      <Typography variant="h6" align="center">
        {` ${t('ValidationMessageSeccond')}`}
      </Typography>
      <DoneOutlineRoundedIcon
        style={{ margin: '40px', color: 'green' }}
        fontSize="large"
        color="secondary"
      />
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          history.push(ROUTES.LOGIN)
        }}
      >
        {t('LogIn')}
      </Button>
    </Paper>
  )
}

export default ValidationMessage
