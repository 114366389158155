import {
  handleError,
  handleResponse,
  getBaseUrl,
} from '../../core/api/apiUtils'
import { SubmitData } from './registrationModel'

export const uploadForm = async (
  data: SubmitData
): Promise<{ insertId: string }> => {
  const url = getBaseUrl('/api/auth/register')
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    return (await handleResponse(response)) as Promise<{ insertId: string }>
  } catch (err) {
    throw handleError(err)
  }
}

export const uploadIMG = async (data: File, id: number): Promise<void> => {
  const url = getBaseUrl('/api/auth/img')
  let type = ''
  if (data) type = data.type.slice(6)
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        type,
        insertID: id.toString(),
      },
      body: data,
    })
    return (await handleResponse(response)) as Promise<void>
  } catch (err) {
    throw handleError(err)
  }
}
