import * as Yup from 'yup'
import formModel from './model'
import { ValidationError } from '../../../core/utils/handleValidationError'

const {
  formField: {
    username,
    first_name,
    last_name,
    email,
    phone_number,
    birthday,
    password,
    confirm_password,
  },
} = formModel

const getSchema = (errors: ValidationError): unknown[] => {
  return [
    Yup.object().shape({
      [username.value]: Yup.string()
        .required(`${username.requiredErrorMsg}`)
        .max(12, 'Max12')
        .notOneOf(
          [`${errors[username.value]?.value}`],
          `${errors[username.value]?.message}`
        ),
      [first_name.value]: Yup.string()
        .required(`${first_name.requiredErrorMsg}`)
        .min(2, 'Min2')
        .max(20, 'Max20'),
      [last_name.value]: Yup.string()
        .required(`${last_name.requiredErrorMsg}`)
        .min(2, 'Min2')
        .max(20, 'Max20'),
      [email.value]: Yup.string()
        .required(`${email.requiredErrorMsg}`)
        .email('MustBeValidEmail')
        .notOneOf(
          [`${errors[email.value]?.value}`],
          `${errors[email.value]?.message}`
        ),
      [phone_number.value]: Yup.number()
        .required('Required')
        .min(6, 'Min6')
        .max(99999999999999999999, 'Max20')
        .typeError('MustBeDigits'),
      [birthday.value]: Yup.date()
        .required(`${birthday.requiredErrorMsg}`)
        .typeError('MustBeValidDate')
        .nullable(true)
        .max(new Date(), 'MustBeValidDate')
        .notOneOf(
          [`${errors[birthday.value]?.value}`],
          `${errors[birthday.value]?.message}`
        ),
      [password.value]: Yup.string()
        .required(`${password.requiredErrorMsg}`)
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
          `PasswordValidationError`
        ),
      [confirm_password.value]: Yup.string()
        .required(`${confirm_password.requiredErrorMsg}`)
        .test('passwords-match', 'PasswordsMustMatch', function (value) {
          return this.parent.password === value
        }),
    }),
  ]
}

export default getSchema
