const model = {
  formId: 'signInForm',
  formField: {
    username: {
      value: 'username',
      label: 'Username/Email*',
      requiredErrorMsg: 'Required',
    },
    password: {
      value: 'password',
      label: 'Password*',
      requiredErrorMsg: 'Required',
    },
  },
}

export default model
