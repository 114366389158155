import { Grid, makeStyles, Paper, Theme, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm'
import Loading from '../../components/Loading'
import AvatarNavElement from '../../components/CustomNavElements/AvatarNavElement'
import { Player } from '../../players/utils/playersModel'

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    width: theme.spacing(11),
    height: theme.spacing(11),
    transition: '0.5s',
    '&:hover': {
      transform: 'scale(1.05)',
    },
    [theme.breakpoints.down('md')]: {
      width: theme.spacing(9),
      height: theme.spacing(9),
    },
  },
  gridWrap: {
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
}))

interface Props {
  data: Player
  type: string
  isLoading: boolean
  loadError: string
}

const TopStatsCard: React.FC<Props> = ({
  data,
  type,
  isLoading,
  loadError,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const {
    username,
    img,
    player_id,
    // points_total,
    // throws_total,
    // ranking_points_total,
    // games_played,
    // games_won,
    player_rank,
  } = data

  let score: string | number = ''
  if (!type && data.average) score = data?.average.toFixed(1)
  else if (type === 'pwratio') score = `${Math.round(data.pw_ratio * 100)}%`
  else if (type === 'lastWinner')
    score = `${moment(data.game_end).format('HH:mm')}h`
  let title = 'AverageBest'
  if (type === 'pwratio') title = 'BestPWRatio'
  else if (type === 'lastWinner') title = 'LastWinner'

  return (
    <Grid item xs={12} md={4}>
      {!loadError ? (
        <Paper elevation={4} style={{ padding: '25px 10px' }}>
          {isLoading ? (
            <Loading loadError="" handleClick={() => ''} />
          ) : (
            <>
              <Grid container spacing={3} className={classes.gridWrap}>
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    style={{ fontWeight: 700 }}
                    align="center"
                  >
                    {t(title)}
                  </Typography>
                </Grid>
                <Grid item xs={4} style={{ justifyContent: 'flex-end' }}>
                  <AvatarNavElement
                    to={player_id}
                    img={img}
                    alt={username}
                    className={classes.avatar}
                  />
                </Grid>
                <Grid
                  item
                  xs={8}
                  style={{ flexDirection: 'column', margin: '25px 0px' }}
                >
                  <Typography
                    variant="h4"
                    style={{ fontWeight: 700 }}
                    align="center"
                  >
                    {username}
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 700, marginTop: '15px' }}
                    align="center"
                  >
                    {`${t('Rank')}: ${player_rank}`}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    variant="h5"
                    style={{ fontWeight: 700 }}
                    align="center"
                  >
                    {type === 'lastWinner' ? (
                      <AccessAlarmIcon fontSize="large" />
                    ) : (
                      t('Score')
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography
                    variant="h4"
                    style={{ fontWeight: 700 }}
                    align="center"
                  >
                    {score}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
        </Paper>
      ) : null}
    </Grid>
  )
}

export default TopStatsCard
