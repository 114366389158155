import { Divider, Grid, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Player } from '../../players/utils/playersModel'
import RankingCard from './RankingCard'
import TableHeader from './TableHeader'

const useStyles = makeStyles(() => ({
  gridWrap: {
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
}))

interface Props {
  data: Player[]
  isMdUp: boolean
}

const Ranking: React.FC<Props> = ({ data, isMdUp }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const padding = isMdUp ? '30px' : '25px'
  return (
    <Paper
      elevation={4}
      style={{ padding: padding, marginBottom: '50px', minHeight: '400px' }}
    >
      <Typography
        variant="h3"
        style={{ fontWeight: 700, marginBottom: '50px' }}
        align="center"
      >
        {t('GlobalStandings')}
      </Typography>

      <Grid container spacing={2} className={classes.gridWrap}>
        <TableHeader isMdUp={isMdUp} />
        {data.map((player, i) => (
          <>
            <RankingCard key={i} data={player} isMdUp={isMdUp} />
            <Divider style={{ width: '100%' }} />
          </>
        ))}
      </Grid>
    </Paper>
  )
}

export default Ranking
