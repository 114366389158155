import React, { ReactNode, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import LanguageSwitch from '../core/appConfig/localization/LanguageSwitch'
import DrawerNavLink from './CustomNavElements/DrawerNavLink'
import * as ROUTES from '../core/routes/constants'
import MenuIcon from '@material-ui/icons/Menu'
import HomeIcon from '@material-ui/icons/Home'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom'
import NavBarLink from './CustomNavElements/NavBarLink'
import { Button, useMediaQuery } from '@material-ui/core'
import { useHistory, useLocation } from 'react-router-dom'
import { logOut } from '../profile/state/profileActions'
import { connect } from 'react-redux'

const drawerWidth = 240
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    minHeight: '64px',
    maxWidth: '100vw',
    left: '0px',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: 'white',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  drawerContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    overflow: 'auto',
  },
}))

interface props {
  logOut: () => void
}

const NavBar: React.FC<props> = ({ logOut }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const history = useHistory()
  const location = useLocation()
  const [open, setOpen] = useState(false)
  const [isAuth, setIsAuth] = useState<string | null>('')

  useEffect(() => {
    setIsAuth(localStorage.getItem('token'))
  }, [location.pathname])

  const navLinks = [
    { label: t('HomePage'), icon: <HomeIcon />, path: ROUTES.ROOT },
  ]
  const toggleDrawer = () => {
    setOpen(!open)
  }

  const handleLogOut = () => {
    localStorage.removeItem('token')
    logOut()
    toggleDrawer()
    history.push(ROUTES.LOGIN)
  }
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar style={{ minHeight: '64px' }}>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <div style={{ flexGrow: 1 }}>
            {isMdUp && (
              <List style={{ display: 'flex' }}>
                {navLinks.map(
                  ({ label, path }, index): ReactNode => (
                    <NavBarLink key={index} to={path} label={label} />
                  )
                )}
              </List>
            )}
          </div>
          <LanguageSwitch />
          {isAuth ? (
            <>
              <IconButton onClick={() => history.push(ROUTES.PROFILE)}>
                <AccountCircleIcon style={{ color: 'white' }} />
              </IconButton>
              {isMdUp && (
                <Button
                  variant="outlined"
                  style={{
                    color: 'white',
                    borderColor: 'white',
                    marginLeft: '15px',
                  }}
                  size="small"
                  onClick={handleLogOut}
                >
                  {t('LogOut')}
                </Button>
              )}
            </>
          ) : isMdUp ? (
            <List style={{ display: 'flex' }}>
              <NavBarLink to={ROUTES.LOGIN} label={t('LogIn')} />
              <NavBarLink to={ROUTES.REGISTER} label={t('SignUp')} />
            </List>
          ) : null}
        </Toolbar>
      </AppBar>
      {!isMdUp && (
        <Drawer
          className={classes.drawer}
          variant={'temporary'}
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="left"
          open={open}
          onClose={toggleDrawer}
        >
          <Toolbar style={{ justifyContent: 'center' }}>
            {!isMdUp && (
              <Typography variant="h4" noWrap color="primary">
                PIKADO.net
              </Typography>
            )}
          </Toolbar>
          <div className={classes.drawerContainer}>
            <List style={{ flexGrow: 1 }}>
              {navLinks.map(
                ({ label, icon, path }, index): ReactNode => (
                  <DrawerNavLink
                    handleClick={toggleDrawer}
                    key={index}
                    to={path}
                    icon={icon}
                    label={label}
                  />
                )
              )}
            </List>
            <List>
              {isAuth ? (
                <DrawerNavLink
                  handleClick={handleLogOut}
                  to={ROUTES.LOGIN}
                  icon={<ExitToAppIcon />}
                  label={t('LogOut')}
                />
              ) : (
                <>
                  <DrawerNavLink
                    handleClick={toggleDrawer}
                    to={ROUTES.LOGIN}
                    icon={<MeetingRoomIcon />}
                    label={t('LogIn')}
                  />
                  <DrawerNavLink
                    handleClick={toggleDrawer}
                    to={ROUTES.REGISTER}
                    icon={<PersonAddIcon />}
                    label={t('SignUp')}
                  />
                </>
              )}
            </List>
          </div>
        </Drawer>
      )}
    </div>
  )
}

const mapDispatchToProps = {
  logOut,
}
export default connect(null, mapDispatchToProps)(NavBar)
