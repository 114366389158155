import React, { useState } from 'react'
import RegistrationForm from './form/RegistrationForm'
import { uploadForm, uploadIMG } from './utils/registrationApi'
import {
  handleValidationError,
  ValidationError,
  ValuesType,
} from '../core/utils/handleValidationError'
import ValidationMessage from './components/ValidationMessage'
import { makeStyles, Theme } from '@material-ui/core'
import { SubmitData } from './utils/registrationModel'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '500px',
    marginTop: '80px',
    [theme.breakpoints.down('xs')]: {
      width: '94%',
      marginTop: '20px',
      marginBottom: theme.spacing(2)
    },
  },
}))

const RegistrationPage: React.FC = () => {

  const classes = useStyles()
  const [registerError, setRegisterError] = useState('')
  const [validationErrors, setValidationErrors] = useState<ValidationError>({})
  const [validationMessage, setValidationMessage] = useState('')

  const handleSubmit = async (
    data: SubmitData,
    setSubmitting: (isSubmitting: boolean) => void,
    setFieldError: (field: string, message: string | undefined) => void,
    selected: string | File
  ): Promise<void> => {
    try {
      const { insertId } = await uploadForm(data)
      await uploadIMG(selected as File, insertId)
      setValidationMessage(data.email)
      setSubmitting(false)
    } catch (err) {
      if(typeof err === "string") setRegisterError(err)
      const errorMessage = handleValidationError(
        err,
        setFieldError,
        data as ValuesType
      )
      setValidationErrors(errorMessage)
      setSubmitting(false)
    }
  }

  return (
    <div className={classes.container}>
      {!validationMessage ? (
        <RegistrationForm
          registerError={registerError}
          validationErrors={validationErrors}
          setRegisterError={setRegisterError}
          handleSubmit={handleSubmit}
        />
      ) : (
        <ValidationMessage validationMessage={validationMessage} />
      )}
    </div>
  )
}

export default RegistrationPage
