import React, { ReactChild } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import { IconButton, Tooltip } from '@material-ui/core'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto'

const InputField = (props: {
  name: string
  icon?: boolean
  type?: string
  setSelected: React.Dispatch<React.SetStateAction<string | File>>
  setOverSizeError: React.Dispatch<React.SetStateAction<string>>
}): JSX.Element => {
  const { t } = useTranslation()
  const { setSelected, icon, setOverSizeError, ...rest } = props

  const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files }: { files: FileList | null } = e.target as HTMLInputElement
    if (files && files[0].size > 2080000) return setOverSizeError(t('ToBigFile'))
    if (files) setSelected(files[0])
  }

  return (
    <>
      {icon ? (
        <Tooltip title={t('Change') as ReactChild}>
          <IconButton component="label">
            <AddAPhotoIcon color="secondary" fontSize="large" />
            <input
              type="file"
              accept="image/*"
              {...rest}
              onChange={(e) => handleSelect(e)}
              hidden
            />
          </IconButton>
        </Tooltip>
      ) : (
        <Button
          color="secondary"
          variant="contained"
          component="label"
          style={{ maxHeight: '40px' }}
        >
          {t('UploadImage')}
          <input
            type="file"
            {...rest}
            onChange={(e) => handleSelect(e)}
            hidden
          />
        </Button>
      )}
    </>
  )
}

InputField.defaultProps = {
  type: 'file',
  variant: 'standard',
  autoComplete: 'new-password',
}

export default InputField
