import {
  handleError,
  handleResponse,
  getBaseUrl,
} from '../../core/api/apiUtils'
import { LoginData } from '../../logIn/loginModel'
import { User } from './profileModel'

export const login = async (data: LoginData): Promise<User> => {
  const url = getBaseUrl('/api/auth/login')
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    return (await handleResponse(response)) as Promise<User>
  } catch (err) {
    throw handleError(err)
  }
}

export const checkSession = async (): Promise<User> => {
  const token = localStorage.getItem('token')
  const url = getBaseUrl(`/api/auth/session/${token}`)
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return (await handleResponse(response)) as Promise<User>
  } catch (err) {
    throw handleError(err)
  }
}
