import {
  CircularProgress,
  Dialog,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import React, { useState, useEffect, ReactElement } from 'react'
import { connect } from 'react-redux'
import { ProfilePageProp, User } from './utils/profileModel'
import { useHistory } from 'react-router-dom'
import * as ROUTES from '../core/routes/constants'
import { checkSession } from './state/profileActions'
import Loading from '../components/Loading'
import ImageOvervies from './components/ImageOverview'
import { uploadIMG } from '../registration/utils/registrationApi'
import ProfileOverview from './components/ProfileOverview'
import ProfileNavigation from './components/ProfileNavigation'
import { Game } from '../games/utils/gamesModel'
import { getGames } from '../games/state/gamesActions'
import { useTranslation } from 'react-i18next'
import Games from '../games/GamesScreen'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '80%',
    maxWidth: '1200px',
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '94%',
      marginTop: '0px',
      marginBottom: theme.spacing(2),
    },
  },
  sectionTitle: {
    margin: '80px',
    fontWeight: 700,
    [theme.breakpoints.down('xs')]: {
      margin: '40px',
    },
  },
}))

const ProfilePage: React.FC<ProfilePageProp> = ({
  profile,
  games,
  checkSession,
  getGames,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isDialogLoading, setIsDialogLoading] = useState(false)
  const [dialogError, setDialogError] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [loadError, setLoadError] = useState('')
  const [selected, setSelected] = useState<File | string>('')
  const [gamesLimit, setGamesLimit] = useState(10)
  const [gamesLimitLoading, setGamesLimitLoading] = useState(false)
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const [popOverMessage, setPopOverMessage] = useState('')
  const { t } = useTranslation()

  useEffect(() => {
    if (selected) {
      setIsDialogOpen(true)
    }
  }, [selected])

  const handleClose = () => {
    setIsDialogOpen(false)
    if (selected) {
      setTimeout(() => {
        setSelected('')
      }, 300)
    }
  }

  const handleImageChange = async () => {
    try {
      setDialogError('')
      setIsDialogLoading(true)
      await uploadIMG(selected as File, profile.id)
      await checkSession()
      setIsDialogLoading(false)
      handleClose()
    } catch (err) {
      setDialogError(err)
      setIsDialogLoading(false)
    }
  }

  const handlePageLoad = async () => {
    try {
      setIsLoading(true)
      await checkSession()
      setIsLoading(false)
    } catch (err) {
      setLoadError(err)
      setIsLoading(false)
      localStorage.removeItem('token')
      history.push(ROUTES.LOGIN)
    }
  }

  const handleGetGames = async (limit: number) => {
    try {
      setIsLoading(true)
      await getGames(profile.id, limit)
      setIsLoading(false)
      setGamesLimitLoading(false)
    } catch (err) {
      setIsLoading(false)
      setLoadError(err)
    }
  }

  const handleGetMoreGames = async () => {
    try {
      setGamesLimitLoading(true)
      setGamesLimit((prev) => prev + 10)
      await getGames(profile.id, gamesLimit + 10)
      setGamesLimitLoading(false)
    } catch (err) {
      setLoadError(err)
    }
  }

  useEffect(() => {
    if (profile.id && !games.length) {
      handleGetGames(gamesLimit)
    } else if (profile.id && games.length) {
      setIsLoading(false)
    }
  }, [profile.id])

  return (
    <div className={classes.container}>
      <ProfileNavigation
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
      />
      {isLoading || loadError ? (
        <Loading loadError={loadError} paper handleClick={handlePageLoad} />
      ) : (
        <ProfileOverview
          data={profile.stats}
          isMdUp={isMdUp}
          popOverMessage={popOverMessage}
          setPopOverMessage={setPopOverMessage}
          setSelected={setSelected}
        />
      )}
      {!isLoading && !loadError && (
        <Typography
          variant="h3"
          className={classes.sectionTitle}
          align="center"
        >
          {t('MyGames')}
        </Typography>
      )}
      {isLoading || loadError ? (
        <Loading loadError="" paper handleClick={handlePageLoad} />
      ) : (
        <>
          <Games
            data={games}
            isMdUp={isMdUp}
            gamesLimitLoading={gamesLimitLoading}
            handleGetMoreGames={handleGetMoreGames}
          />
          {games.length % 10 === 0 && (
            <Tooltip title={t('LoadMoreGames') as ReactElement}>
              <IconButton onClick={handleGetMoreGames}>
                {gamesLimitLoading ? (
                  <CircularProgress size={22} />
                ) : (
                  <AddCircleIcon color="secondary" fontSize="large" />
                )}
              </IconButton>
            </Tooltip>
          )}
        </>
      )}

      <Dialog
        maxWidth="md"
        open={isDialogOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        {selected ? (
          <ImageOvervies
            selected={selected}
            isLoading={isDialogLoading}
            requestError={dialogError}
            handleCancelation={handleClose}
            handleConfirm={handleImageChange}
          />
        ) : (
          <img style={{ height: '270px' }} src={profile.qr_url}></img>
        )}
      </Dialog>
    </div>
  )
}

const mapStateToProps = (state: { profile: User; games: Game[] }) => {
  return {
    profile: state.profile,
    games: state.games,
  }
}

const mapDispatchToProps = {
  checkSession,
  getGames,
}
export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage)
