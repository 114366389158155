import React, { useState, useEffect, ReactElement } from 'react'
import { useField } from 'formik'
import Grid from '@material-ui/core/Grid'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { useTranslation } from 'react-i18next'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import renderLabelText from './utils/renderLabelText'

export default function DatePickerField(props: {
  disabled?: boolean
  name: string
  label: string
  format: string
  minDate: Date
  maxDate?: Date
  classes?: { root: string }
  inputVariant?: 'standard' | 'filled' | 'outlined' | undefined
}): ReactElement {
  const { t } = useTranslation()
  const [field, meta, helper] = useField(props)
  const { touched, error } = meta
  const { setValue } = helper
  const isError = touched && error && true
  const { value } = field
  const [selectedDate, setSelectedDate] = useState<
    Date | MaterialUiPickersDate | null
  >(null)

  const { label, ...rest } = props

  useEffect(() => {
    if (value) {
      const date = new Date(value)
      setSelectedDate(date)
    }
  }, [value])

  const onChange = (date: MaterialUiPickersDate | number) => {
    if (date) {
      setSelectedDate(date as MaterialUiPickersDate)
      const d = new Date(date as number)
      const res = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`
      setValue(res)
    } else {
      setValue(date)
    }
  }

  return (
    <Grid container>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          {...field}
          {...rest}
          inputVariant={props.inputVariant}
          label={renderLabelText(label)}
          value={selectedDate}
          onChange={onChange}
          format="dd.MM.yyyy"
          error={isError as boolean}
          invalidDateMessage={isError && error}
          helperText={isError && t(error as string)}
          inputProps={{
            autoComplete: 'off',
          }}
          placeholder="DD.MM.YYYY"
        />
      </MuiPickersUtilsProvider>
    </Grid>
  )
}

DatePickerField.defaultProps = {
  inputVariant: 'standard',
}
