import React from 'react'
import { Divider, Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

interface Props {
  isMdUp: boolean
}

const TableHeader: React.FC<Props> = ({ isMdUp }) => {
  const { t } = useTranslation()
  const gridXs = isMdUp ? 5 : 9
  return (
    <>
      <Grid item xs={1}>
        <Typography variant="body1" align="center">
          {t('Rank')}
        </Typography>
      </Grid>
      <Grid item xs={gridXs}>
        <Typography variant="body1" align="center">
          {t('Player')}
        </Typography>
      </Grid>
      {isMdUp && (
        <>
          <Grid item xs={2}>
            <Typography variant="body1" align="center">
              {t('AverageThrow')}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body1" align="center">
              {t('BestPWRatio')}
            </Typography>
          </Grid>
        </>
      )}
      <Grid item xs={2}>
        <Typography variant="body1" align="center">
          {t('Points')}
        </Typography>
      </Grid>

      <Divider style={{ width: '100%' }} />
    </>
  )
}

export default TableHeader
