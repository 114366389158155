import React, { ReactElement, useRef, useState } from 'react'
import i18n from './i18n'
import { connect } from 'react-redux'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import { handleLangSelection } from '../state/configActions'
import PublicOutlinedIcon from '@material-ui/icons/PublicOutlined';

interface Props {
  choosenLang: string
  handleLangSelection: (lang: string) => void
}
const options = ['sr', 'en']
const LanguageSwitch: React.FC<Props> = ({
  choosenLang,
  handleLangSelection,
}): ReactElement => {
  const anchorEl = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  const handleLanguageSelection = (option: string) => {
    i18n.changeLanguage(option)
    handleLangSelection(option)
    localStorage.setItem('choosenLanguage', option)
    handleClose()
  }

  const handleClose = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div ref={anchorEl}>
      <IconButton onClick={handleClose}>
        <PublicOutlinedIcon style={{ color: 'white' }} />
      </IconButton>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl.current}
        keepMounted
        open={isOpen}
        onClose={handleClose}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            selected={choosenLang === option}
            onClick={() => handleLanguageSelection(option)}
          >
            {option.toUpperCase()}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

const mapStateToProps = (state: { appConfig: { choosenLang: string } }) => {
  return {
    choosenLang: state.appConfig.choosenLang,
  }
}

const mapDispatchToProps = {
  handleLangSelection,
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSwitch)
