import {
  Avatar,
  Divider,
  Grid,
  Paper,
  Popover,
  Typography,
} from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import React, { useRef } from 'react'
import Avatar_IMG from '../../../asset/resource/avatar.png'
import { useTranslation } from 'react-i18next'
import ImputFile from '../../components/formFields/InputFileField'
import ReportProblemRoundedIcon from '@material-ui/icons/ReportProblemRounded'
import { Player } from '../../players/utils/playersModel'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    maxWidth: '1200px',
    marginTop: '10px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '0px',
      marginBottom: theme.spacing(2),
    },
  },
  gridWrap: {
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
  },
  avatar: {
    width: theme.spacing(22),
    height: theme.spacing(22),
    [theme.breakpoints.down('md')]: {
      width: theme.spacing(18),
      height: theme.spacing(18),
    },
    [theme.breakpoints.down('xs')]: {
      width: theme.spacing(14),
      height: theme.spacing(14),
    },
  },
  paper: {
    padding: '7px 15px',
    display: 'flex',
  },
  changeImgWrap: {
    position: 'absolute',
    bottom: '0px',
    left: '30%',
    zIndex: 1000,
    [theme.breakpoints.down('md')]: {
      left: '20%',
    },
    [theme.breakpoints.down('xs')]: {
      left: '22%',
    },
  },
}))

interface Props {
  data: Player
  isMdUp: boolean
  popOverMessage?: string
  setPopOverMessage?: React.Dispatch<React.SetStateAction<string>>
  setSelected?: React.Dispatch<React.SetStateAction<string | File>>
}

const ProfileOverview: React.FC<Props> = ({
  data,
  isMdUp,
  popOverMessage,
  setPopOverMessage,
  setSelected,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const anchorEl = useRef<HTMLDivElement | null>(null)

  const {
    username,
    img,
    games_won,
    games_played,
    points_total,
    throws_total,
    ranking_points_total,
    player_rank,
  } = data
  const spacing = isMdUp ? 5 : 3
  const padding = isMdUp ? '50px' : '25px'
  return (
    <Paper
      elevation={2}
      style={{ padding: padding }}
      className={classes.container}
    >
      <Grid
        ref={anchorEl}
        container
        spacing={spacing}
        className={classes.gridWrap}
      >
        <Grid item xs={12} sm={6} style={{ position: 'relative' }}>
          {setPopOverMessage && setSelected && (
            <>
              <Popover
                open={!!popOverMessage}
                anchorEl={anchorEl.current}
                onClose={() => setPopOverMessage('')}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                classes={{ paper: classes.paper }}
              >
                <ReportProblemRoundedIcon
                  color="error"
                  style={{ marginRight: '7px' }}
                />
                <Typography color="error" variant="body2">
                  {popOverMessage}
                </Typography>
              </Popover>
              <div className={classes.changeImgWrap}>
                <ImputFile
                  icon
                  setSelected={setSelected}
                  name="img"
                  setOverSizeError={setPopOverMessage}
                />
              </div>
            </>
          )}
          <Avatar
            alt={username}
            src={img ? img : Avatar_IMG}
            className={classes.avatar}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h2" style={{ fontWeight: 700 }} align="center">
            {username}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            alignItems: 'flex-start',
            flexDirection: 'column',
            marginTop: '25px',
          }}
        >
          <Typography variant="h4" style={{ fontWeight: 700 }} align="center">
            {`${t('Standings')}`}
          </Typography>
          <Divider style={{ width: '100%' }} />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h5" style={{ fontWeight: 700 }} align="center">
            {t('Rank')}
          </Typography>
          <Typography
            variant="h3"
            style={{ fontWeight: 700, marginTop: '15px' }}
            align="center"
          >
            {player_rank}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h5" style={{ fontWeight: 700 }} align="center">
            {t('Points')}
          </Typography>
          <Typography
            variant="h3"
            style={{ fontWeight: 700, marginTop: '15px' }}
            align="center"
          >
            {ranking_points_total.toFixed(1)}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            alignItems: 'flex-start',
            flexDirection: 'column',
          }}
        >
          <Typography variant="h4" style={{ fontWeight: 700 }} align="center">
            {t('Games')}
          </Typography>
          <Divider style={{ width: '100%' }} />
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography variant="h6" style={{ fontWeight: 700 }} align="center">
            {t('Played')}
          </Typography>
          <Typography
            variant="h4"
            style={{ fontWeight: 700, marginTop: '15px' }}
            align="center"
          >
            {games_played}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography variant="h6" style={{ fontWeight: 700 }} align="center">
            {t('Won')}
          </Typography>
          <Typography
            variant="h4"
            style={{ fontWeight: 700, marginTop: '15px' }}
            align="center"
          >
            {games_won}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography variant="h6" style={{ fontWeight: 700 }} align="center">
            {t('AverageThrow')}
          </Typography>
          <Typography
            variant="h4"
            style={{ fontWeight: 700, marginTop: '15px' }}
            align="center"
          >
            {points_total &&
              throws_total &&
              (points_total / throws_total).toFixed(1)}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography variant="h6" style={{ fontWeight: 700 }} align="center">
            {t('BestPWRatio')}
          </Typography>
          <Typography
            variant="h4"
            style={{ fontWeight: 700, marginTop: '15px' }}
            align="center"
          >
            {games_won &&
              games_played &&
              Math.floor((games_won / games_played) * 100)}
            %
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ProfileOverview
