import { Avatar } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import Avatar_IMG from '../../../asset/resource/avatar.png'
import * as ROUTES from '../../core/routes/constants'

interface Props {
  alt: string
  img: string
  className: string
  to: number
}

const AvatarNavElement: React.FC<Props> = ({ alt, img, className, to }) => {
  return (
    <Link to={`${ROUTES.PLAYER}/${to}`}>
      <Avatar alt={alt} src={img ? img : Avatar_IMG} className={className} />
    </Link>
  )
}

export default AvatarNavElement
