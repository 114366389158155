import * as types from './actionsTypes'
import { USER_LOGOUT } from '../../profile/state/actionsTypes'
import initialState from '../../core/state/reducers/initialState'
import { Game } from '../utils/gamesModel'

const loginReducer = (
  state = initialState.games as Game[],
  action: { type: string; data: Game[] }
): Game[] => {
  switch (action.type) {
    case types.GET_GAMES_SUCCESS:
      return action.data as Game[]
    case USER_LOGOUT:
      return []
    default:
      return state
  }
}

export default loginReducer
