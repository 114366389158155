import {
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
} from '@material-ui/core'
import React from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) => ({
  activeLink: {
    color: theme.palette.primary.main,
    '& svg': {
      fill: theme.palette.primary.main,
    },
  },
}))

interface ListItemLinkProps {
  icon?: React.ReactElement
  label: string
  to: string
  handleClick: () => void
}

const ListItemLink = (props: ListItemLinkProps): JSX.Element => {
  const { icon, label, to, handleClick } = props
  const classes = useStyles()

  const renderLink = React.useMemo(
    () =>
      // eslint-disable-next-line react/display-name
      React.forwardRef<HTMLAnchorElement, Omit<NavLinkProps, 'to'>>(
        (itemProps, ref) => <NavLink to={to} ref={ref} {...itemProps} />
      ),
    [to]
  )

  return (
    <li onClick={handleClick}>
      <ListItem
      color=""
        button
        component={renderLink}
        activeClassName={classes.activeLink}
      >
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={label} />
      </ListItem>
      <Divider />
    </li>
  )
}

export default ListItemLink
