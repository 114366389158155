import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import moment from 'moment'
import React from 'react'
import TableHeader from './TableHeader'
import { Game } from '../utils/gamesModel'
import RankingCard from './RankingCard'
import { useTranslation } from 'react-i18next'
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded'

interface Props {
  data: Game
  isMdUp: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    maxWidth: '1200px',
    marginTop: '10px',
    display: 'flex',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      width: '94%',
      marginTop: '0px',
      marginBottom: theme.spacing(2),
    },
  },
  gridWrap: {
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
  },
  accordionSummary: {
    minHeight: '65px',

    // backgroundColor: fade(theme.palette.grey[200], 1),
  },
  locationIcon: {
    color: theme.palette.success.main,
    marginRight: '8px',
  },
  accordion: {
    margin: '10px 0px',
  },
}))

const GamesOverview: React.FC<Props> = ({ data, isMdUp }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [isExpanded, setExpanded] = React.useState<boolean>(false)

  const { game_finish, game_out, game_type, end, location_name, gamePlayers } =
    data

  const handleChange = () => {
    setExpanded(!isExpanded)
  }
  return (
    <Accordion
      expanded={isExpanded}
      onChange={handleChange}
      className={classes.accordion}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        className={classes.accordionSummary}
      >
        <Grid container spacing={2} className={classes.gridWrap}>
          <Grid item xs={7} style={{ flexDirection: 'row' }}>
            <LocationOnRoundedIcon className={classes.locationIcon} />
            <Typography variant="h6" style={{ fontWeight: 700 }} align="center">
              {location_name}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" align="center">
              {`${moment(end).format('DD:MM:YYYY, HH:mm')}h`}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2} className={classes.gridWrap}>
          <TableHeader isMdUp={isMdUp} />
          {gamePlayers.map((stats, i) => (
            <RankingCard key={i} data={stats} isMdUp={isMdUp} />
          ))}
          <Grid item xs={12}>
            <Divider style={{ width: '100%' }} />
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="body2" align="center">
              {t('GameType')}
            </Typography>
            <Typography variant="h6" style={{ fontWeight: 700 }} align="center">
              {game_type}
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography variant="body2" align="center">
              {t('GameFinish')}
            </Typography>{' '}
            <Typography variant="h6" style={{ fontWeight: 700 }} align="center">
              {game_finish}
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography variant="body2" align="center">
              {t('GameOut')}
            </Typography>
            <Typography variant="h6" style={{ fontWeight: 700 }} align="center">
              {game_out}
            </Typography>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default GamesOverview
