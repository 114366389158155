import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import { useTranslation } from 'react-i18next'
import { Button, Paper } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  paperRoot: {
    width: '100%',
    minHeight: '280px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '50px',
  },
  noPaperRoot: {
    width: '100%',
    minHeight: '140px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loader: {
    marginTop: theme.spacing(2),
  },
}))

interface Props {
  loadError: string
  paper?: boolean
  handleClick: () => Promise<void> | string
}
const Loading: React.FC<Props> = ({
  loadError,
  paper,
  handleClick,
}): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  if (paper) {
    return (
      <Paper className={classes.paperRoot}>
        {loadError ? (
          <>
            <Typography variant="h5" color="error" align="center">
              {t(loadError)}
            </Typography>
            <WarningRoundedIcon
              fontSize="large"
              color="error"
              style={{ margin: '5px 0px 20px' }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleClick()}
            >
              {t('TryAgain')}
            </Button>
          </>
        ) : (
          <>
            <CircularProgress size={40} className={classes.loader} />
          </>
        )}
      </Paper>
    )
  } else {
    return (
      <div className={classes.noPaperRoot}>
        {loadError ? (
          <>
            <Typography variant="h5" color="error" align="center">
              {t(loadError)}
            </Typography>
            <WarningRoundedIcon
              fontSize="large"
              color="error"
              style={{ margin: ' 5px 0px 20px' }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleClick()}
            >
              {t('TryAgain')}
            </Button>
          </>
        ) : (
          <>
            <CircularProgress size={40} className={classes.loader} />
          </>
        )}
      </div>
    )
  }
}

export default Loading
